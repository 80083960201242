<template>
  <div class="py-3 py-md-5">
    <v-card
      flat
      class="mx-auto"
      max-width="600"
      :loading="isMakingNetworkRequest"
      :color="backgroundColor"
    >
      <v-card-text v-if="model">
        <!-- Show the logo -->
        <div class="mb-4 mb-md-6">
          <img
            :src="logo"
            height="60"
          >
        </div>

        <!-- Show the content -->
        <div v-if="screen === 'landing-page'">
          <!-- Show the title -->
          <div
            class="text-h5 font-weight-bold"
            :style="{ color: titleColor }"
          >
            {{ model.intro_title }}
          </div>

          <!-- Show the text -->
          <div
            class="text-body-2 mt-2"
            :style="{ color: textColor }"
            v-html="model.intro_description"
          ></div>

          <!-- Show the media if available -->
          <v-carousel
            v-if="model.intro_media && model.intro_media.length"
            height="300"
            class="mt-4"
          >
            <v-carousel-item
              v-for="(media, index) in model.intro_media"
              :key="index"
            >
              <v-img
                v-if="media.type === 'image'"
                :src="storageUrl(media.value)"
                aspect-ratio="1"
                height="300"
                contain
              ></v-img>

              <!-- If it's a youtube video -->
              <youtube-embed
                v-else-if="media.type === 'youtube'"
                :url="media.value"
                :height="300"
                width="100%"
              ></youtube-embed>
            </v-carousel-item>
          </v-carousel>

          <!-- Show the continue button -->
          <div class="d-flex justify-end mt-4">
            <v-btn
              depressed
              @click="screen = 'form'"
              :color="buttonBackgroundColor"
              :style="{ color: buttonTextColor }"
            >
              {{ model.intro_cta_text || "Continue" }}
            </v-btn>
          </div>
        </div>

        <!-- For the form -->
        <div v-else-if="screen === 'form'">
          <!-- Show the title -->
          <div
            class="text-h5 font-weight-bold"
            :style="{ color: titleColor }"
          >
            {{ model.form_title || "Please fill this form" }}
          </div>

          <!-- Show the text -->
          <div
            class="text-body-2 mt-2"
            :style="{ color: textColor }"
            v-html="model.form_description"
          ></div>

          <!-- Show a divider -->
          <v-divider class="my-4" />

          <!-- Show the inputs for name -->
          <div class="mt-4">
            <v-text-field
              v-model="formData.firstName"
              label="First Name"
              placeholder="John"
              :background-color="backgroundColor"
              :color="textColor"
              :hide-details="!$v.formData.firstName.$anyError"
              :error-messages="$v.formData.firstName.$anyError ? ['Please enter a valid value'] : null"
              @blur="$v.formData.firstName.$touch"
              outlined
            />

            <v-text-field
              v-model="formData.lastName"
              label="Last Name"
              placeholder="Doe"
              :background-color="backgroundColor"
              :color="textColor"
              :hide-details="!$v.formData.lastName.$anyError"
              :error-messages="$v.formData.lastName.$anyError ? ['Please enter a valid value'] : null"
              @blur="$v.formData.lastName.$touch"
              class="mt-4"
              outlined
            />
          </div>

          <!-- If we have platforms -->
          <template
            v-if="model.platform_fields && model.platform_fields.length"
          >
            <!-- Show a divider -->
            <v-divider class="my-4" />

            <!-- Show a heading -->
            <div
              class="text-body-1 font-weight-bold"
              :style="{ color: titleColor }"
            >
              Social Media Presence
            </div>

            <!-- Show the text -->
            <div
              class="text-body-2"
              :style="{ color: textColor }"
            >
              Please provide your social media usernames for the following platforms
            </div>

            <!-- Ask for platform inputs -->
            <div
              v-for="platform in model.platform_fields"
              :key="'platform-' + platform"
              class="mt-4"
            >
              <v-text-field
                v-model="formData.platforms[platform]"
                :label="capitalizeString(platform)"
                :background-color="backgroundColor"
                :color="textColor"
                :hide-details="!$v.formData.platforms[platform].$anyError"
                :error-messages="$v.formData.platforms[platform].$anyError ? ['Please enter a valid value'] : null"
                @blur="$v.formData.platforms[platform].$touch"
                outlined
              />
            </div>
          </template>

          <!-- If we have contact fields -->
          <template
            v-if="model.contact_fields && model.contact_fields.length"
          >
            <!-- Show a divider -->
            <v-divider class="my-4" />

            <!-- Show a heading -->
            <div
              class="text-body-1 font-weight-bold"
              :style="{ color: titleColor }"
            >
              Contact Details
            </div>

            <!-- Show the text -->
            <div
              class="text-body-2"
              :style="{ color: textColor }"
            >
              Please provide your correct contact details, it'll help us reach out to you
            </div>

            <!-- Ask for contact inputs -->
            <div
              v-for="contact in model.contact_fields"
              :key="'contact-' + contact"
              class="mt-4"
            >
              <v-text-field
                v-model="formData.contacts[contact]"
                :label="capitalizeString(contact)"
                :background-color="backgroundColor"
                :color="textColor"
                :hide-details="!$v.formData.contacts[contact].$anyError"
                :error-messages="$v.formData.contacts[contact].$anyError ? ['Please enter a valid value'] : null"
                @blur="$v.formData.contacts[contact].$touch"
                outlined
              />
            </div>
          </template>

          <!-- If we have price fields -->
          <template
            v-if="model.price_fields && model.price_fields.length"
          >
            <!-- Show a divider -->
            <v-divider class="my-4" />

            <!-- Show a heading -->
            <div
              class="text-body-1 font-weight-bold"
              :style="{ color: titleColor }"
            >
              Price Details
            </div>

            <!-- Show the text -->
            <div
              class="text-body-2"
              :style="{ color: textColor }"
            >
              Please provide us your estimate charges for the following services
            </div>

            <!-- Ask for price inputs -->
            <div
              v-for="price in model.price_fields"
              :key="'price-' + price"
              class="mt-4"
            >
              <v-text-field
                v-model="formData.prices[price]"
                :label="capitalizeString(price)"
                :background-color="backgroundColor"
                :color="textColor"
                :hide-details="!$v.formData.prices[price].$anyError"
                :error-messages="$v.formData.prices[price].$anyError ? ['Please enter a valid value'] : null"
                :prefix="model.currency ? currencySymbolMap[model.currency] : null"
                @blur="$v.formData.prices[price].$touch"
                outlined
              />
            </div>
          </template>

          <!-- Show the continue button -->
          <div class="d-flex justify-end mt-4">
            <v-btn
              depressed
              @click="handleSubmit"
              :color="buttonBackgroundColor"
              :style="{ color: buttonTextColor }"
              :disabled="isMakingNetworkRequest"
              :loading="isMakingNetworkRequest"
            >
              {{ model.form_cta_text || "Submit" }}
            </v-btn>
          </div>
        </div>

        <!-- For the success page -->
        <div v-else-if="screen === 'success'">
          <!-- Show an alert -->
          <v-alert
            type="success"
            icon="check_circle"
          >
            Your form has been submitted!
          </v-alert>

          <!-- Show the title -->
          <div
            class="text-h5 font-weight-bold"
            :style="{ color: titleColor }"
          >
            {{ model.thank_you_title || "Thank you!" }}
          </div>

          <!-- Show the text -->
          <div
            class="text-body-2 mt-2"
            :style="{ color: textColor }"
            v-html="model.thank_you_description || 'We have received your form submission. We will get back to you shortly.'"
          ></div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators"

// Import children components
const YoutubeEmbed = () => import(/* webpackChunkName: "youtube-embed" */ '@/blocks/common/YoutubeEmbed.vue')

// Import helper data
import currencySymbolMap from "@/helpers/currencySymbolMap"

// Export the SFC
export default {
  // Name of the component
  name: "InviteForm",

  // Define the components
  components: {
    YoutubeEmbed
  },

  // Define local data variables
  data: () => ({
    // Whether or not is loading
    isMakingNetworkRequest: true,

    // Get the invite form model
    model: null,

    // The screen to show
    screen: null, // "landing-page", "form", "success"

    // Define the form
    formData: {
      firstName: "",
      lastName: "",
    },

    currencySymbolMap
  }),

  // Define computable properties
  computed: {
    /**
     * Get the logo URL
     *
     * @returns {string}
     */
    logo() {
      return this.model && this.model.logo ? this.storageUrl(this.model.logo) : this.host.images.logo
    },

    /**
     * Get the background color
     *
     * @returns {string}
     */
    backgroundColor() {
      return this.model?.style?.background_color || "#ffffff"
    },

    /**
     * Get the text color
     *
     * @returns {string}
     */
    textColor() {
      return this.model?.style?.text_color || "#000000"
    },

    /**
     * Get the title color
     *
     * @returns {string}
     */
    titleColor() {
      return this.model?.style?.title_color || "#000000"
    },

    /**
     * Get the button background color
     *
     * @returns {string}
     */
    buttonBackgroundColor() {
      return this.model?.style?.button_background_color || "#000000"
    },

    /**
     * Get the button text color
     *
     * @returns {string}
     */
    buttonTextColor() {
      return this.model?.style?.button_text_color || "#ffffff"
    }
  },

  // Define vuelidate validations
  validations() {
    const object = {
      formData: {
        firstName: {
          required: required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        lastName: {
          maxLength: maxLength(100)
        }
      }
    }

    // If form data has platforms
    if (this.formData.platforms) {
      // Initialize the platforms object
      object.formData.platforms = {}

      // Loop through the platforms
      for (const key in this.formData.platforms) {
        // Set the validation
        object.formData.platforms[key] = {
          required: required,
          minLength: minLength(2),
          maxLength: maxLength(100)
        }
      }
    }

    // If form data has contacts
    if (this.formData.contacts) {
      // Initialize the contacts object
      object.formData.contacts = {}

      // Loop through the contacts
      for (const key in this.formData.contacts) {
        // Set the validation
        object.formData.contacts[key] = {
          required: required,
          minLength: minLength(2),
          maxLength: maxLength(100)
        }
      }
    }

    // If form data has prices
    if (this.formData.prices) {
      // Initialize the prices object
      object.formData.prices = {}

      // Loop through the prices
      for (const key in this.formData.prices) {
        // Set the validation
        object.formData.prices[key] = {
          required: required,
          numeric: numeric
        }
      }
    }

    // Return the object
    return object
  },

  // Define local method functions
  methods: {
    /**
     * Get the invite form model using slug
     *
     * @returns {void}
     */
    async fetchModel() {
      // Mark as loading
      this.isMakingNetworkRequest = true

      // Try making network request
      try {
        // Get the response
        const response = await axios({
          url: `/api/invite-forms/${this.$route.params.slug}`,
        })

        // Set the model
        this.model = response.data

        // Set the screen
        this.screen = this.model.skip_intro ? "form" : "landing-page"

        // Loop through platform fields
        if (this.model.platform_fields && this.model.platform_fields.length) {
          // Initialize the platforms object
          this.$set(this.formData, "platforms", {})

          // Loop through the fields
          this.model.platform_fields.forEach(field => {
            // Set the field
            this.$set(this.formData.platforms, field, "")
          })
        }

        // Loop through contact fields
        if (this.model.contact_fields && this.model.contact_fields.length) {
          // Initialize the contacts object
          this.$set(this.formData, "contacts", {})

          // Loop through the fields
          this.model.contact_fields.forEach(field => {
            // Set the field
            this.$set(this.formData.contacts, field, "")
          })
        }

        // Loop through prices fields
        if (this.model.price_fields && this.model.price_fields.length) {
          // Initialize the prices object
          this.$set(this.formData, "prices", {})

          // Loop through the fields
          this.model.price_fields.forEach(field => {
            // Set the field
            this.$set(this.formData.prices, field, "")
          })
        }

        // Mark as not loading
        this.isMakingNetworkRequest = false
      }
      // Catch any errors
      catch (error) {
        // Log the error
        console.error(error)

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try again later" })
      }
    },

    /**
     * Handle the form submission
     *
     * @returns {void}
     */
    async handleSubmit() {
      // Show the loader
      this.isMakingNetworkRequest = true

      // Validate the form
      await this.$v.$touch()

      // If the form is invalid
      if (this.$v.$invalid) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Please fill all the required fields correctly" })

        // Mark as not loading
        this.isMakingNetworkRequest = false

        // Return
        return
      }

      // Try making network request
      try {
        // Get the response
        await axios({
          url: `/api/invite-forms/${this.model.id}`,
          method: "POST",
          data: {
            first_name: this.formData.firstName,
            last_name: this.formData.lastName,
            platforms: Object.entries((this.formData.platforms || {})).map(([key, value]) => ({ key, value })),
            contacts: Object.entries((this.formData.contacts || {})).map(([key, value]) => ({ key, value })),
            prices: Object.entries((this.formData.prices || {})).map(([key, value]) => ({ key, value }))
          }
        })

        // Set the screen
        this.screen = "success"
      }
      // Catch any errors
      catch (error) {
        // Log the error
        console.error(error)

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try again later" })
      }
      // Nonetheless
      finally {
        // Mark as not loading
        this.isMakingNetworkRequest = false
      }
    }
  },

  /**
   * As soon as the component is created
   *
   * @returns {void}
   */
  created() {
    // Fetch the invite form model
    this.fetchModel()
  }
}
</script>
